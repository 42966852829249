var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-business" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form dialog-form-inline",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rule,
            inline: true,
            "label-width": "110px",
            "label-position": "left",
            disabled: _vm.disabled
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "上级节点：", prop: "parentName" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.addForm.parentName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "parentName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.parentName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "节点名称：", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入节点名称" },
                model: {
                  value: _vm.addForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "节点类型：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", disabled: !_vm.typeVisible },
                  model: {
                    value: _vm.addForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "type", $$v)
                    },
                    expression: "addForm.type"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "公司", value: 1 } }),
                  _c("el-option", { attrs: { label: "车队", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _vm.addForm.type == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "所属系统", prop: "system" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择所属系统" },
                      model: {
                        value: _vm.addForm.system,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "system", $$v)
                        },
                        expression: "addForm.system"
                      }
                    },
                    _vm._l(_vm.systemList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.addForm.type < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "机构代码：", prop: "orgCa" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addForm.orgCa,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "orgCa",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.orgCa"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm.type < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "法人代表：", prop: "corporation" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addForm.corporation,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "corporation",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.corporation"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm.type < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "地址：", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addForm.address,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.address"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm.type < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "经度：", prop: "longitude" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addForm.longitude,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "longitude",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.longitude"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm.type < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "纬度：", prop: "latitude" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addForm.latitude,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "latitude",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.latitude"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm.type < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "服务单位：", prop: "serviceOrgan" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addForm.serviceOrgan,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "serviceOrgan",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.serviceOrgan"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm.type < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "投诉电话：", prop: "complaintTel" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addForm.complaintTel,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "complaintTel",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.complaintTel"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm.type < 2
            ? _c(
                "el-form-item",
                { attrs: { label: "公司电话：", prop: "companyPhone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addForm.companyPhone,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "companyPhone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.companyPhone"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "联系人：", prop: "linkMan" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.addForm.linkMan,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "linkMan",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.linkMan"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话：", prop: "phone" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.addForm.phone,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "phone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.phone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "工单负责企业：", prop: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.wxConfigId,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "wxConfigId", $$v)
                    },
                    expression: "addForm.wxConfigId"
                  }
                },
                _vm._l(_vm.wxConfigList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.companyName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticStyle: { "padding-left": "1.5vh" } }, [
        _vm._v("设备故障报警设置：")
      ]),
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form dialog-form-inline",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rule,
            inline: true,
            "label-width": "110px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "故障负责人：", prop: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    filterable: "",
                    remote: "",
                    "collapse-tags": "",
                    placeholder: "",
                    "remote-method": _vm.getFaultOwner,
                    loading: _vm.faultOwnerLoading,
                    "reserve-keyword": false
                  },
                  model: {
                    value: _vm.addForm.faultOwner,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "faultOwner", $$v)
                    },
                    expression: "addForm.faultOwner"
                  }
                },
                _vm._l(_vm.faultOwnerList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.userName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警类型：", prop: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", clearable: "", placeholder: "" },
                  model: {
                    value: _vm.addForm.faultAlarmType,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "faultAlarmType", $$v)
                    },
                    expression: "addForm.faultAlarmType"
                  }
                },
                _vm._l(_vm.faultAlarmTypeList, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.dictCode,
                      attrs: { label: item.dictValue, value: item.dictCode }
                    },
                    [
                      _c("span", { staticStyle: { "padding-right": "20px" } }, [
                        _vm._v(_vm._s(item.dictValue))
                      ])
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }